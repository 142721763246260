import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useEffect, useRef, useState } from "react";

import type { SciAmUserPayload } from "@/types";
import { useEnvironment } from "~core/hooks/use-environment";
import { UseApi, useApi, UseApiOptions } from "~features/auth/hooks/use-api";
import useAuth0Env from "~features/auth/hooks/use-auth0-env";

interface CookieState {
  /**
   * The state of the SciAm User token.
   *
   * - `null` - No stale cookie set, we will poll for updates. Default value on first render.
   * - `false` - User has a fresh token, no action is needed.
   * - `true` - Token is marked as stale, we will quietly update the user's JWT.
   * - `"warn"` - Backend issue while requesting JWT, we will keep the stale JWT and try again.
   */
  stale: boolean | "warn" | null;
  /** The current SciAm JWT. */
  token: string | null;
  /**
   * Whether the cookie has changed since initial page load.
   * This is useful for showing a "refresh to see changes" message.
   */
  changed: boolean;
}
function useSciAmJwtCookieState() {
  // SciAm JWTs use the Auth0 client ID as a namespace to avoid collisions with other environments
  const { clientId } = useAuth0Env();
  const initialState: CookieState = {
    stale: null,
    token: null,
    changed: false,
  };

  const ref = useRef(initialState);
  const [state, setCookies] = useState(initialState);
  const [payload, setPayload] = useState<SciAmUserPayload | null>(null);

  // JWT state is stored in cookies
  // This functionality may need to change in the future should we move towards an HttpOnly cookie
  function update() {
    const userToken = Cookies.get(`sa_user.${clientId}`) || null;
    const userStale = Cookies.get("sa_user_stale") || null;
    const parsedToken = userToken && (jwtDecode(userToken) as SciAmUserPayload | null);

    // We only want to set a token string if it's parsable.
    const token = !!parsedToken ? userToken : null;

    // Map of stale cookie values to boolean values
    // When set, the stale cookie can either be 0 (fresh), 1 (stale), or "warn" (backend issue)
    const staleStates = { "0": false, "1": true, warn: "warn" };

    ref.current = {
      token,

      // If the cookie is missing, we assume the token is fresh but allow polling for updates
      stale: staleStates[userStale] || null,

      // The token can only be considered changed after the initial page load.
      // The changed state is `undefined` until we have a token to compare against, so we use
      // that as an easy signal.
      changed: typeof state.changed === "boolean" ? state.token !== token : false,
    };

    // Only update the payload object when the token changes
    if (state.token !== userToken) {
      setPayload(parsedToken);
    }
    setCookies(ref.current);
  }

  function clear() {
    Cookies.remove(`sa_user.${clientId}`);
    Cookies.remove("sa_user_stale");
    ref.current = { ...initialState };
    setPayload(null);
    setCookies(ref.current);
  }

  return { ref, state, payload, update, clear };
}

function debounce(fn: () => void, delay = 300) {
  let timeout: NodeJS.Timeout;
  return () => {
    clearTimeout(timeout);
    timeout = setTimeout(fn, delay);
  };
}

export interface SciAmJwtSyncContextValue extends CookieState {
  /** The parsed SciAm JWT */
  payload: SciAmUserPayload | null;
  /** Whether we're checking for new entitlements */
  polling: boolean;
  /** Whether we're fetching a fresh token */
  updating: boolean;
  /** Whether the SciAm JWT is ready to use */
  ready: boolean;
  /** Check for updates, refresh token if necessary. */
  check: () => void;
  /** Update the SciAm JWT. */
  update: () => void;
  /** Delete the SciAm JWT cookie and clear token state. */
  clear: () => void;
}
export function useProvideSciAmJwtSync(force = false): SciAmJwtSyncContextValue {
  // ========================================================================
  // Sync API endpoints
  // ========================================================================

  // Response types for our token sync API endpoints
  type PollingValue = { changed: boolean };
  type SciAmTokenResponse = { token: string; expires: number };
  type EndpointError = { error: string; message?: string };

  // Inject mock data in development
  const [injectedData, setInjectedData] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (!import.meta.env.DEV) return;
    setInterval(() => {
      const mockData = window.localStorage.getItem("sa_jwt_mock");
      if (!mockData) setInjectedData(undefined);
      setInjectedData("mockData=" + encodeURIComponent(mockData));
    }, 2000);
  }, []);

  // Our JWT syncing endpoints in Express follow the same request pattern:
  const apiPostConfig: UseApiOptions = {
    immediate: false,
    method: "POST",
    authMode: "require",
    headers: { "Content-Type": "application/x-www-form-urlencoded" },
    // Setting a `body` tells useApi to append the auth token here instead of URL params
    body: "",
  };

  // Polling endpoint checks for changes in user entitlements
  const poll = useApi<PollingValue | EndpointError>("/auth/sciam/poll/", apiPostConfig);

  // Token endpoint exchanges an Auth0 token for a fresh SciAm JWT
  const token = useApi<SciAmTokenResponse | EndpointError>("/auth/sciam/", {
    ...apiPostConfig,
    // Request a fresh Auth0 token when updating (but fall back to a cached token if necessary)
    authMode: "require-fresh",
    // Inject mock data in development
    body: (import.meta.env.DEV && injectedData) || "",
  });

  // Log API responses to the console
  useApiLogger(poll, "poll");
  useApiLogger(token, "token");

  // ========================================================================
  // Cookie state management
  // ========================================================================

  // User state is managed by the server through sa_user* cookies
  const { ref, state, payload, update, clear } = useSciAmJwtCookieState();

  // Check for cookie state changes on mount and after API responses
  useEffect(update, [poll.data, token.data, poll.error, token.error]);

  // ========================================================================
  // Basic setup and configuration
  // ========================================================================

  // Chargebee must be enabled to sync SciAm JWTs
  const env = useEnvironment();
  const enabled = env.entitlements.provider === "chargebee";

  // User must be logged in for any of this to work
  const { isAuthenticated, isLoading } = useAuth0();

  /** Don't make requests while the API is busy or unavailable */
  const allowed = enabled && isAuthenticated;
  const busy = poll.loading || token.loading;
  const canMakeRequest = allowed && !busy;

  // Force an update on mount if requested
  useEffect(() => {
    if (isLoading || !force || !allowed) return;
    console.log("[SciAm JWT] [token] forcing update");
    token.update();
  }, [isLoading]);

  // Check for updates when Chargebee events occur
  useEffect(() => {
    const updateDebounced = debounce(poll.update, 2000);
    window.addEventListener("chargebee:portal-close", updateDebounced);
    window.addEventListener("chargebee:portal-update_payment", updateDebounced);
    window.addEventListener("chargebee:portal-update_subscription", updateDebounced);
    window.addEventListener("chargebee:checkout-close", updateDebounced);
    window.addEventListener("chargebee:checkout-success", updateDebounced);

    return () => {
      window.removeEventListener("chargebee:portal-close", updateDebounced);
      window.removeEventListener("chargebee:portal-update_payment", updateDebounced);
      window.removeEventListener("chargebee:portal-update_subscription", updateDebounced);
      window.removeEventListener("chargebee:checkout-close", updateDebounced);
      window.removeEventListener("chargebee:checkout-success", updateDebounced);
    };
  }, []);

  // ========================================================================
  // Cooldown logic
  // Prevent accidental excessive polling and token refreshing
  // TODO: Figure out if this is really necessary
  // ========================================================================
  const COOLDOWN_PERIOD = 30000; // 30-second cooldown
  const lastPollTime = useRef(0);
  const lastUpdateTime = useRef(0);

  /** Check if enough time has passed since the last poll */
  const canPoll = () => {
    const now = Date.now();
    if (now - lastPollTime.current > COOLDOWN_PERIOD) {
      lastPollTime.current = now;
      return true;
    }
    return false;
  };

  /** Check if enough time has passed since the last update */
  const canRefreshToken = () => {
    const now = Date.now();
    if (now - lastUpdateTime.current > COOLDOWN_PERIOD) {
      lastUpdateTime.current = now;
      return true;
    }
    return false;
  };

  // ========================================================================
  // Background Polling logic
  // ========================================================================

  /** Only poll when we don't know if the user's JWT is stale */
  const unknownStaleState = state.token && typeof state.stale !== "boolean";
  /** Don't poll if we've already checked for updates */
  const alreadyPolled = !!poll.error || typeof poll?.data?.["changed"] === "boolean";
  /** Should we poll for updates? */
  const shouldPoll = canMakeRequest && unknownStaleState && !alreadyPolled;
  useEffect(() => {
    if (!shouldPoll || !canPoll()) return;
    console.log("[SciAm JWT] [poll] checking for entitlements updates");
    poll.update();
  }, [shouldPoll]);

  // ========================================================================
  // SciAm Token auto-refresh logic
  // ========================================================================

  /** Don't do anything while the API is loading, user is logged out, or if there's an error */
  const canUpdate = canMakeRequest && !token.error;
  /** Is the user logged into Auth0 but missing a SciAm JWT? */
  const missingToken = !state.token;
  /** Does the user have a stale JWT (i.e., polling endpoint detected changes) */
  const hasUpdates = state.token && !!state.stale;
  /** Should we update the JWT? */
  const shouldUpdate = canUpdate && (missingToken || hasUpdates);
  useEffect(() => {
    if (!shouldUpdate || !canRefreshToken()) return;

    // Prevent duplicate requests caused by this effect running before cookie state updates
    if (missingToken && ref.current.token) return;
    if (hasUpdates && !ref.current.stale) return;

    console.log("[SciAm JWT] [token] updating", { missingToken, hasUpdates });
    token.update();
  }, [shouldUpdate]);

  return {
    ...state,
    payload,
    polling: poll.loading,
    updating: token.loading,
    ready:
      // User has a SciAm JWT
      !!state.token ||
      // User is definitely logged out (thus this hook is a no-op)
      (!isAuthenticated && !isLoading) ||
      // If we don't have a cookie, check whether our state is at least settled
      (typeof state.token !== "undefined" && (!token.loading || !!token.error)),

    // Allow manual checking and updating, e.g. on user account interactions
    check: () => {
      update();
      poll.update();
    },
    update: token.update,
    clear,
  };
}

/** Quick utility to log `useApi()` responses to the console */
function useApiLogger(api: UseApi, label: string) {
  useEffect(() => {
    api.data && console.log(`[SciAm JWT] [${label}] success`);
  }, [api.data]);

  useEffect(() => {
    api.error && console.error(`[SciAm JWT] [${label}] error`, api.error);
  }, [api.error]);
}

export default useProvideSciAmJwtSync;
